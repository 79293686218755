import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import {
  EXCHANGER_KEY_WORD,
  MODULES_WARNINGS
} from '../../../../../../../../../server/constants';
import { getUpperModulesParams } from '../../../../../../../../../server/models/config/buildingDescription/services/hydraulic.model';
import { fetchUpperModulesNd } from '../../../../../../../api/modules.api';
import FormInput from '../../../../../../../components/Form/FormInput';
import Section from '../../../../../../../components/Section/Section';
import WarningBadge from '../../../../../../../components/WarningBadge';
import { INPUT_TYPE } from '../../../../../../../constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import { isOutOfRange } from '../../../../../../../utils/data.utils';
import './UpperModulesSection.css';

const UpperModulesSection = ({ onFormChange, addError, removeErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const handleSizingChange = async (exchanger, value) => {
    try {
      const { ConfigSstID } = config.ConfigsSst[0];
      const { services } = config.ConfigsSst[0].Data;
      services.upperModules[exchanger].sizingCapacity = value;
      // on set les modules avec les bons DN et le bon nom
      services.upperModules = await fetchUpperModulesNd(
        ConfigSstID,
        upperModules
      );
      onFormChange(config);
    } catch (error) {
      throw error;
    }
  };

  const getUpperModuleError = (exchanger) => {
    if (!exchanger.sizingCapacity) {
      return i18n._(
        'config.services.hydraulic.upperModules.warning.noSizingCapacity'
      );
    } else if (
      isOutOfRange(
        exchanger.nominalDiameter,
        MODULES_WARNINGS.UPPER_MODULES_ND_RANGE
      )
    ) {
      return i18n._(
        'config.services.hydraulic.upperModules.warning.nonExistingNd',
        {
          nominalDiameter: exchanger.nominalDiameter
        }
      );
    } else {
      return;
    }
  };
  //#endregion

  //#region [render]
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  const { upperModules } = config.ConfigsSst[0].Data.services;
  const params = getUpperModulesParams(services);
  const evapError = getUpperModuleError(upperModules[EXCHANGER_KEY_WORD.EVAP]);
  const condError = getUpperModuleError(upperModules[EXCHANGER_KEY_WORD.COND]);
  return (
    <Section
      title={i18n._('config.services.hydraulic.upperModules.title')}
      level={2}
      open
    >
      <div className='custom-table-wrapper'>
        <table className='upper-modules-table'>
          <thead>
            <tr>
              <th></th>
              <th colSpan={3}>
                {i18n._('config.services.hydraulic.upperModules.evapLine')}
              </th>
              <th colSpan={3}>
                {i18n._('config.services.hydraulic.upperModules.condLine')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ minWidth: '160px' }}>
                {i18n._('config.services.hydraulic.upperModules.upperModule')}
              </td>
              <td>
                {i18n._(
                  'config.services.hydraulic.upperModules.sizing.capacity'
                )}
              </td>
              <td>{i18n._('config.services.hydraulic.upperModules.fluid')}</td>
              <td>{i18n._('config.services.hydraulic.upperModules.nd')}</td>
              <td>
                {i18n._(
                  'config.services.hydraulic.upperModules.sizing.capacity'
                )}
              </td>
              <td>{i18n._('config.services.hydraulic.upperModules.fluid')}</td>
              <td>{i18n._('config.services.hydraulic.upperModules.nd')}</td>
            </tr>
            <tr>
              <td>{upperModules.name}</td>
              <td>
                <FormInput
                  param={params[EXCHANGER_KEY_WORD.EVAP].sizingCapacity}
                  value={upperModules[EXCHANGER_KEY_WORD.EVAP].sizingCapacity}
                  onBlur={(value) =>
                    handleSizingChange(EXCHANGER_KEY_WORD.EVAP, value)
                  }
                  className='form-input'
                  addError={addError}
                  removeError={() => removeErrors(1)}
                  type={INPUT_TYPE.NUMBER}
                  unit
                />
              </td>
              <td>
                {i18n._(
                  `heatTransferFluid.${heatpumps.settings.heatTransferFluid}`
                )}
              </td>
              <td>
                <div className='nominalDiameter-warning'>
                  {upperModules[EXCHANGER_KEY_WORD.EVAP].nominalDiameter}
                  {evapError && (
                    <WarningBadge
                      tooltipContent={evapError}
                      tooltipId='evapNd'
                      portalContainer={document.body}
                      place='bottom'
                      iconSize='medium'
                    />
                  )}
                </div>
              </td>
              <td>
                <FormInput
                  param={params[EXCHANGER_KEY_WORD.COND].sizingCapacity}
                  value={upperModules[EXCHANGER_KEY_WORD.COND].sizingCapacity}
                  onBlur={(value) =>
                    handleSizingChange(EXCHANGER_KEY_WORD.COND, value)
                  }
                  className='form-input'
                  addError={addError}
                  removeError={() => removeErrors(1)}
                  type={INPUT_TYPE.NUMBER}
                  unit
                />
              </td>
              <td>{i18n._('heatTransferFluid.water')}</td>
              <td>
                <div className='nominalDiameter-warning'>
                  {upperModules[EXCHANGER_KEY_WORD.COND].nominalDiameter}
                  {condError && (
                    <WarningBadge
                      tooltipContent={condError}
                      tooltipId='condNd'
                      portalContainer={document.body}
                      place='bottom'
                      iconSize='medium'
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Section>
  );
  //enominalDiameterregion
};
export default UpperModulesSection;
