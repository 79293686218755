import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext } from 'react';
import {
  EQUIPMENT_FAMILY,
  MODULES_WARNINGS,
  UNIT
} from '../../../../../../../server/constants';
import WarningBadge from '../../../../../components/WarningBadge';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import {
  getTableValue,
  isArrNullOrEmpty,
  isOutOfRange
} from '../../../../../utils/data.utils';
import SubEquipmentRow from './SubEquipmentRow';

const HeatpumpsRows = ({
  hpsList,
  subEquipementList,
  handleShowSubEquipment
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const getLowerModuleError = (lowerModule) => {
    if (!lowerModule) {
      return i18n._('config.equipmentList.warning.noModule');
    } else if (isArrNullOrEmpty(lowerModule.equipment)) {
      return i18n._('config.equipmentList.warning.noEquipment');
    } else {
      return;
    }
  };
  //#endregion

  //#region [render]
  const { services } = config.ConfigsSst[0].Data;
  const { evaporator, condensor } = services.upperModules;
  // on vérifie que les prix du module haut PAC sont bien présents au catalogue (et donc comptés)
  const isUpperModulePriceComplete =
    !isOutOfRange(
      evaporator.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_ND_RANGE
    ) &&
    !isOutOfRange(
      condensor.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_ND_RANGE
    );
  return (
    <Fragment>
      {hpsList.map((hp, index) => {
        const lowerModuleError = getLowerModuleError(hp.lowerModule);
        const lowerModuleRef = `${hp.position}_${hp?.lowerModule?.name}`;
        const upperModuleRef = `${hp.position}_${hp.upperModule.name}`;
        const visibleLowerModuleEquipment = hp.lowerModule?.equipment?.filter(
          (equipment) => equipment.family === EQUIPMENT_FAMILY.CLIMATIC
        );
        const hasSubEquipment = !isArrNullOrEmpty(visibleLowerModuleEquipment);
        const prices = hp?.lowerModule?.prices;
        return (
          <Fragment key={`${index}_${hp.model}_eq`}>
            {/* PAC */}
            <tr className={!!index ? 'equipment-list-separator' : ''}>
              <td></td>
              <td className='bold'>{hp.model}</td>

              <td colSpan={2} className='bold'>
                {getTableValue(i18n, hp.price, UNIT.EURO, 0)}
              </td>
              <td colSpan={5}></td>
              <td className='bold'></td>
            </tr>
            {/* Module bas de la PAC*/}
            <tr>
              <td>
                {hasSubEquipment && (
                  <FontAwesomeIcon
                    className='equipment-list-chevron'
                    onClick={() => handleShowSubEquipment(lowerModuleRef)}
                    icon={
                      subEquipementList.includes(lowerModuleRef)
                        ? faChevronDown
                        : faChevronRight
                    }
                  />
                )}
              </td>
              <td className='bold'>
                <div className='equipment-list-warning-badge-cell'>
                  {hp?.lowerModule?.name ??
                    i18n._('config.hps.lowerModule.notFound')}
                  {lowerModuleError && (
                    <WarningBadge
                      tooltipContent={lowerModuleError}
                      tooltipId={'lowerModule' + '_' + hp.position}
                      portalContainer={document.body}
                      place='bottom'
                      iconSize='small'
                    />
                  )}
                </div>
              </td>
              <td colSpan={2} className='bold'>
                {getTableValue(
                  i18n,
                  prices?.moduleEquipmentTotal,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td className='bold'>
                {getTableValue(i18n, prices?.manufacturingTotal, UNIT.EURO, 0)}
              </td>
              <td>
                {getTableValue(
                  i18n,
                  prices?.hydraulicAndStructure,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td>
                {getTableValue(i18n, prices?.factoryInsulation, UNIT.EURO, 0)}
              </td>
              <td>
                {getTableValue(i18n, prices?.siteInsulation, UNIT.EURO, 0)}
              </td>
              <td>
                {getTableValue(i18n, prices?.equipmentConfig, UNIT.EURO, 0)}
              </td>
              <td className='bold'>
                {getTableValue(i18n, prices?.total, UNIT.EURO, 0)}
              </td>
            </tr>
            {subEquipementList.includes(lowerModuleRef) && (
              <SubEquipmentRow list={visibleLowerModuleEquipment} />
            )}
            {/* Module haut de la PAC */}
            <tr>
              <td>
                <FontAwesomeIcon
                  className='equipment-list-chevron'
                  onClick={() => handleShowSubEquipment(upperModuleRef)}
                  icon={
                    subEquipementList.includes(upperModuleRef)
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </td>
              <td className='bold'>
                <div className='equipment-list-warning-badge-cell'>
                  {hp.upperModule.name}
                  {!isUpperModulePriceComplete && (
                    <WarningBadge
                      tooltipContent={i18n._(
                        'config.equipmentList.warning.incompletePrice'
                      )}
                      tooltipId={'injectionUpperModule'}
                      portalContainer={document.body}
                      place='bottom'
                      iconSize='small'
                    />
                  )}
                </div>
              </td>
              <td colSpan={2} className='bold'>
                {getTableValue(
                  i18n,
                  hp.upperModule.prices.moduleEquipmentTotal,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td className='bold'>
                {getTableValue(
                  i18n,
                  hp.upperModule.prices.manufacturingTotal,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td>
                {getTableValue(
                  i18n,
                  hp.upperModule.prices.hydraulicAndStructure,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td className='bold'>
                {getTableValue(i18n, hp.upperModule.prices.total, UNIT.EURO, 0)}
              </td>
            </tr>
            {subEquipementList.includes(upperModuleRef) && (
              <SubEquipmentRow list={hp.upperModule.equipment} />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default HeatpumpsRows;
